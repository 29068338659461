<template>
  <main class="masque">
    <div class="text">
      <div
        class="li flex"
        v-for="condition in masque.left"
        :key="condition.title"
      >
        <div class="sli top lundi">{{ condition.title }}</div>
        <div class="sli" v-html="condition.text"></div>
      </div>
    </div>
    <div class="text">
      <div
        class="li flex"
        v-for="condition in masque.right"
        :key="condition.title"
      >
        <div class="sli top lundi">{{ condition.title }}</div>
        <div class="sli" v-html="condition.text"></div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "masque",
  data() {
    return {
      lang: "en"
    };
  },
  created() {
    if (this.$store.state.headmasque.length === 0) {
      this.$store.dispatch("loadHeadMasque");
    }
  },
  computed: {
    masque() {
      return this.$store.state.headmasque;
    }
  }
};
</script>

<style lang="scss" scoped>
.masque {
  font-feature-settings: none;
  display: grid;
  grid-template-columns: minmax(auto, 700px) 1fr;
  grid-gap: var(--lh);
  .top {
    // white-space: nowrap;
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    .top {
      white-space: normal;
    }
  }
}
</style>
